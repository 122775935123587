import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"
import posed, { PoseGroup } from 'react-pose';
import { connect } from 'react-redux'
import Preload from 'react-preload'
import prefetchImages from 'prefetch-image'

import { Layout, Footer, PrivateRoute, MetaTags } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { updateSettings } from '../store/settings';
import { lightRed } from '../styles/colors'

import logo from '../assets/images/logo.png'

import TheField from '../modules/the-field'
import Home from '../modules/home'
import ThePriming from '../modules/the-priming'
import Afterward from '../modules/afterward'
//import Register from '../modules/register'
import Section from '../templates/section';
import SubSection from '../templates/subSection';
import Gallery from '../templates/gallery';

const RouteContainer = posed.div({
	enter: { 
		opacity: 1,
		delay: 0,
		beforeChildren: true,
		transition: { 
			duration: 2000 
		}
	},
	exit: { 
		opacity: 0,
		transition: { 
			duration: 1000 
		}
	}
});

const PosedRouter = ({ children }) => (
	<Location>
	  	{({ location }) => (
			<PoseGroup
				animateOnMount={true}
			>
				<RouteContainer key={location && location.key || ''}>
					<Router location={location}>{children}</Router>
				</RouteContainer>
			</PoseGroup>
		)}
	</Location>
);

class Index extends React.Component {

	state = {
		loading: true
	}

	componentWillMount = () => {
		this.setState({
			sections: this.props.data.allWordpressSections.edges,
			bgimages: JSON.parse(this.props.data.wordpressHome.bgimages)
		})
	};
	
	componentDidMount() {
		const { bgimages } = this.state;

		// prefetchImages(bgImg)
	}
	
	componentWillUpdate(nextProps, nextState) {
		const { updateSettings } = this.props;
		
		if (this.props['*'] !== nextProps['*']) {
			updateSettings({
				audio: null
			})
		}
	}

	renderFieldGalleryPages = () => {
		const acf = JSON.parse(this.props.data.wordpressFieldgallery.acf_json)
						
		return ['sonic', 'visual', 'text'].map((value, i) => {
			if (acf[`${value}_display_module`]) {   
				return (
					<PrivateRoute 
						key={i}
						path={`/app/the-field/gallery/${value}`} 
						slug={'the-field'}
						theField={true}
						data={this.props.data.wordpressFieldgallery}
						type={value}
						component={Gallery}
					/>
				)
			} 
		})
	}

	renderLoading = () => {
		const { bgimages } = this.state;

		return (
			<Preload
				images={bgimages}
				onSuccess={(e) => this.setState({loading: false})}
				loadingIndicator={(
					<Loading>
						<Spinner/>
					</Loading>
				)}
				children={null}
			/>
		)
	}
	
	render() {       
		const { sections, loading } = this.state;

		if (loading) {
			return this.renderLoading()
		}

		return (
			<PosedRouter>

				<PrivateRoute path="/app/home" component={Home}/>

				{/* The Priming */}

				<ThePriming path="/app/the-priming"/>

				{/* The Field */}

				<PrivateRoute path="/app/the-field" component={TheField}/>

				<PrivateRoute 
					path="/app/field-suite" 
					data={{
						node: this.props.data.wordpressFieldsubpage,
						gallery: this.props.data.wordpressFieldgallery
					}}
					component={SubSection}	
				/>

				{/* The Field Gallery */}

				{this.renderFieldGalleryPages()}		

				{/* Afterward */}

				<PrivateRoute path="/app/afterward" component={Afterward}/>

				<PrivateRoute 
					path="/app/afterward/gallery" 
					component={Gallery} 
					afterward={true}
					afterwardItems={this.props.data.allWordpressCollections.edges}
					afterwardData={this.props.data.wordpressAfterward}
				/>

				{/* Create Section Pages */}

				{sections.map((section, i) => {
					return (
						<PrivateRoute 
							key={i}
							path={`/app/${section.node.slug}`} 
							component={Section}
							data={section}
						/>
					)
				})}

				{sections.map((section, i) => {
					return section.node.subs.map((sub, i) => {
						return (
							<PrivateRoute 
								key={i}
								path={`/app/${section.node.slug}/${sub.slug}`} 
								slug={sub.slug}
								component={SubSection}
								data={section}
							/>
						)
					})
				})}

				{sections.map((section, i) => {
					return section.node.subs.map((sub, i) => {
						const acf = JSON.parse(sub.acf_json);
						
						return ['sonic', 'visual', 'text'].map((value) => {
							if (acf[`${value}_display_module`]) {   
								return (
									<PrivateRoute 
										key={i}
										path={`/app/${section.node.slug}/${sub.slug}/gallery/${value}`} 
										slug={sub.slug}
										component={Gallery}
										data={section}
										type={value}
									/>
								)
							} 
						})
					})
				})}

			</PosedRouter>
		)
	}
}

const Spinner = styled.div`
	background-image: url(${require('../assets/images/loader-oval.svg')});
	height: 38px;
	width: 38px;
`
const Loading = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 99;
	position: absolute;
	top: 0;
	left: 0;
	background: #D9D9D7;
	height: 100vh;
	width: 100vw;
`

export const query = graphql`
	query {
		allWordpressSections {
			edges {
				node {
					title
					slug
					acf_json
					subs {
						title
						slug
						acf_json
					}
				}
			}
		}

		wordpressAfterward {
			title 
			slug
			acf_json
		}

		allWordpressCollections {
			edges {
				node {
					title 
					slug
					acf_json
				}
			}
		}
		wordpressGalleryitems {
			items
		}

		wordpressFieldsubpage {
			acf_json
			slug
		}

		wordpressFieldgallery {
			acf_json
			slug
		}

		wordpressHome {
            bgimages
		}

	}
`

const mapDispatchToProps = dispatch => ({
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

export default connect(null, mapDispatchToProps)(Index);
