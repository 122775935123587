import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled, {css} from 'styled-components'
import { find, findIndex } from 'lodash'
import { connect } from 'react-redux'

import { Layout, Footer, MetaTags } from '../components'
import { container, padding, bgImage, bgIcon, hoverState } from '../styles/global'
import { lightRed, darkGrey, green, lightBlue } from '../styles/colors'

import logo from '../assets/images/logo-watermark.png'
import iconSonic from '../assets/images/suite-ear-sonic-icon.png'
import iconSonicHover from '../assets/images/suite-ear-hover-active.png'
import iconVisual from '../assets/images/suite-eye-visual-icon.png'
import iconVisualHover from '../assets/images/suite-eye-hover-active.png'
import iconText from '../assets/images/suite-hand-text-icon.png'
import iconTextHover from '../assets/images/suite-hand-hover-active.png'

import { getSettings, fetchSettings, updateSettings } from '../store/settings';
import { isClient, media } from '../styles/utils';
import { Fade } from '../transitions';
import { getBackground } from '../utils';

class SubSection extends React.Component {

	state = {
        changeBackground: false,
        visitedSubSectionTracked: false,
	}

	componentWillMount = () => {
        const data = this.props.data.node;
        let theField = data.slug == 'the-field-subpage';
        let subSectionData;
        let subSectionIndex;

        if (data.subs) {
            subSectionData = find(data.subs, { slug: this.props.slug } );
            subSectionIndex = findIndex(data.subs, { slug: this.props.slug } );
        }

        if (theField) {
            subSectionData = data
        }

        const acf = JSON.parse(subSectionData.acf_json)

        this.setState({      
            data: subSectionData,
            acf: acf,
            sectionData: !theField && data,
            sectionAcf: !theField && JSON.parse(data.acf_json)
        })

        setTimeout(() => {
            !theField && isClient() && window.visitedSubSection(subSectionIndex, data.slug)
        }, 2000)  

        if (acf.audio_file) {
            this.props.updateSettings({
                audio: acf.audio_file.url
            })
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                changeBackground: true
            })
        }, 4000);
    }
    
    renderGalleryLinks = () => {
        const { acf, data, sectionData } = this.state;

        return ['sonic','visual','text'].map((type, i) => {  
            if (acf[`${type}_display_module`]) {   
                return (
                    <Icon
                        key={i}
                        type={type}
                        to={`/app/${sectionData.slug}/${data.slug}/gallery/${type}`}
                    />
                )   
            } 
        })
    }  
    
    renderGalleryLinksField = () => {
        const { gallery } = this.props.data;
        const acf = JSON.parse(gallery.acf_json)

        return ['sonic','visual','text'].map((type, i) => {  
            if (acf[`${type}_display_module`]) {   
                return (
                    <Icon
                        key={i}
                        type={type}
                        to={`/app/the-field/gallery/${type}`}
                    />
                )   
            } 
        })
    }  

    renderExploreAfterward = () => {
        const { data } = this.state;
        let canAccessAfterward = false;

        if (isClient()) {
            const sectionsVisited = JSON.parse(window.localStorage.sections);
            const afterward = find(sectionsVisited, {slug: 'afterward'})
            
            if (afterward?.inactive == false) {
                canAccessAfterward = true
            }
        }

        if (data.slug == 'release') { 
            return (
                <Fade order={4}>
                    <Afterward
                        to={'/app/afterward'}
                        canAccessAfterward={canAccessAfterward}
                    >
                        <Subtitle>Then...</Subtitle>
                        <Heading>
                            Explore <br/>the afterward
                        </Heading>
                        <BackArrow/>
                    </Afterward>
                </Fade>
            )
        }
    }

    renderSectionNav = () => {
        const { data, acf, sectionData, sectionAcf } = this.state;

        return (
            <SectionNav>
                {sectionData.subs.map((section) => {
                    const active = section.slug == data.slug;
                    return (
                        <>
                           <SectionNavItem
                                color={sectionAcf.hero_colour}
                                active={active}
                                onClick={() => !active && navigate(`/app/${sectionData.slug}/${section.slug}`)}
                            >
                                <Heading>{section.title}</Heading>
                            </SectionNavItem>
                            <Line/>
                        </>
                    )
                })}
            </SectionNav>
        )
    }

    renderFieldInfo = () => {
        const { acf, data } = this.state;

        if (data.slug == 'the-field-subpage') {
            return (
                <Fade order={4}>
                    <FieldContent>
                        <Heading>The Field</Heading>
                        <Description
                            dangerouslySetInnerHTML={{__html: acf.thefield_description}}
                        />
                        <Explore>
                            <SubTitle
                                color={darkGrey}
                            >
                                {`Explore the Field`}
                            </SubTitle>
                            <Icons>
                                {this.renderGalleryLinksField()}
                            </Icons>
                        </Explore>
                    </FieldContent>
                </Fade>
            )   
        }
    }
    
	render() {       
        const { sectionData, data, acf, sectionAcf, changeBackground } = this.state;

        return (
			<Layout
                textBox={acf.text_content}
                textBoxAudio={acf?.text_content_audio}
                delay={acf.background_image_initial}
            >
                <MetaTags 
                    title={`Adventures in the Field`}
                />

                <Wrapper
                    bgImage={getBackground(acf, changeBackground)}
                >
                 

                    {data && data.slug == 'the-field-subpage' && (
                        <Fade order={4}>
                            <Logo 
                                to={'/app/home'}
                            />           
                        </Fade>
                    )}

                    <Container>
                        
                        {sectionData && (
                            <ParentSection>
                                <Fade order={2}>
                                    <SectionTitle
                                        color={sectionAcf.hero_colour}
                                        to={`/app/${sectionData.slug}`}
                                    >
                                        {sectionData.title}
                                    </SectionTitle>
                                    {this.renderSectionNav()}
                                </Fade>
                            </ParentSection>
                        )}


                        {sectionAcf && (
                            <Fade order={2}>
                                <Title
                                    section={data.slug}
                                    color={sectionAcf.hero_colour}
                                >
                                    {data.title}
                                </Title>
                            </Fade>
                        )}

                        {acf.schematic && (
                            <Fade order={3}>
                                <Schematic 
                                    width={acf.schematic.sizes['large-width'] / 2}
                                    src={acf.schematic.sizes.large}
                                />
                            </Fade>
                        )}

                        {this.renderFieldInfo()}

                        {sectionAcf && (
                            <Fade order={3}>
                                <Explore>
                                    <SubTitle
                                        color={sectionAcf.secondary_colour}
                                    >
                                        {`Explore the ${data.title} Zone`}
                                    </SubTitle>
                                    <Icons>
                                        {this.renderGalleryLinks()}
                                    </Icons>
                                </Explore>
                            </Fade>
                        )}

                        {this.renderExploreAfterward()}
                  

                    </Container>
                </Wrapper>

                <Footer 
                    color={sectionAcf.footer_text_colour}
                />

			</Layout>	
		)
	}
}



const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    min-height: 100vh;
    display: flex;
    flex: 1;
    transition: background 2s ease;
`

const Description = styled.div``

const Container = styled.div`
	${container};
    ${padding};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    flex: 1;

`

const ParentSection = styled.div`
    position: absolute;
    top: 26px;

    > * {
        display: flex;
    }

    ${media.phone`
        position: absolute;
        margin-top: 24px;
        left: 0;
        width: 100vw;

        display: flex;
        justify-content: center;
    `}
`
const SectionTitle = styled(Link)`
    display: flex;
    font-family: 'LatoWeb';
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 0.5em;
    color: ${props => props.color};
    
    ${media.phone`
        text-align: center;
    `}
`

const Title = styled.div`
    font-family: 'LatoWebBold';
    text-transform: uppercase;
    font-size: 36px;
    letter-spacing: 0.3em;
    color: ${props => props.color};
    text-align: center;
    margin-bottom: 40px;
    max-width: 350px;

    ${media.phone`
        font-size: 28px;
        margin-top: 32px;
    `}
`

const Schematic= styled.img`
    height: auto;
`

const Explore = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 45px;
`

const SubTitle = styled.div`
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.1em;
    color: ${props => props.color};
    text-align: center;
    margin-bottom: 24px;
`

const Icons = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`

const Icon = styled(Link)`
    display: flex;

    &:not(:last-child) {
        margin-right: 24px;
    }

    ${bgIcon}
    width: 52px;
    height: 52px;

    &:hover {
        transition: 0.4s all ease;
        cursor: pointer;
    }
    
    ${props => {
        if (props.type=='sonic') return css`
            background-image: url(${iconSonic});

            &:hover {
                background-image: url(${iconSonicHover});
            }
        `
        if (props.type=='visual') return css`
            background-image: url(${iconVisual});

            &:hover {
                background-image: url(${iconVisualHover});
            }
        `
        if (props.type=='text') return css`
            background-image: url(${iconText});

            &:hover {
                background-image: url(${iconTextHover});
            }
        `
    }}
`

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 107px;
    height: 86px;
    
    ${media.tablet`
        width: 88px;
        height: 71px;
        top: 5px;
        left: 5px;
    `}
`

// Afterward

const Subtitle = styled.div``
const Heading = styled.div``
const BackArrow = styled.div`
    background-image: url(${require('../assets/images/orange-button.png')});
    ${bgImage};
    height: 36px;
    width: 36px;
    margin-right: 9px;  

    position: absolute;
    right: -48px;
    bottom: 5px;
`

const Afterward = styled(Link)`
    text-align: right;
    margin-left: 300px;
    position: relative;
    display: flex;  
    flex-direction: column;

    ${Subtitle} {
        font-size: 13px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        opacity: 0.7;
        color: white;
        margin-bottom: 2px;
    }

    ${Heading} {
        font-family: 'LatoWebBold';
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: white;
    }

    ${media.phone`
        margin-left: 0;
        margin-top: 48px;
    `}

    display: none;

    ${props => {
        if (props.canAccessAfterward) return css`
           display: flex;
        `
    }}
`


const FieldContent = styled.div`
    position: absolute;
    
    ${bgIcon}
    background: rgba(255,255,255, 0.2);
    border-radius: 50%;
    height: 600px;
    width: 600px;

    top: 50%;
    left: calc(50%);
    transform: translate(-50%, -50%);    

    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center;

    ${Heading} {
        font-family: 'LatoWeb';
        font-size: 35px;
        font-weight: bold;
        line-height: 75px;
        letter-spacing: 10.5px;
        text-align: center;
        text-transform: uppercase;
        color: #4d4d4d;
        margin-top: 65px;
    } 

    ${Description} {
        max-width: 73.5%;
        margin-top: 32px;

        p {
            font-family: 'LatoWebLight';
            font-size: 16px;
            line-height: 28px;
            color: ${darkGrey};

            
            em {
                font-family: 'LatoWebLightItalic';
                font-style: italic;
            }

            strong {
                font-size: 14px;
            }
       }
    }

    ${Explore} {
        margin-top: 32px;
    } 

    ${media.phone`
        width: 100vw;
        background: none;
        position: initial;
        transform: none;
        height: auto;
        padding-bottom: 64px;
    `}
`


// Section Nav

const SectionNav = styled.div`
    display: flex;
    align-items: center;
    margin-left: 31px;
    align-items: center;

    ${media.phone`
		display: none;
	`}
`

const SectionNavItem = styled.div`
    width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid ${props => props.color};
    cursor: pointer;
    transition: background 0.2s ease;
    position: relative;

    ${props => {
		if (props.active) return css`
			background: ${props => props.color};
            cursor: auto;
		`
	}}

    &:hover {
        background: ${props => props.color};

        ${Heading} {
            opacity: 1;
        }
    }

    ${Heading} {
        font-size: 10px;
        letter-spacing: 0.2em;
        color: ${props => props.color};
        text-transform: uppercase;
        font-family: 'LatoWebBold';
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translate(-50%, 10%);
        transition: opacity 0.2s ease;
        opacity: 0;
        pointer-events: none;
    }
`

const Line = styled.div`
	width: 16px;
	background-image: linear-gradient(90deg, #000, #000 50%, transparent 75%, transparent 100%);    
	background-size: 4px 2px;
	background-position: 10px 0px;
    background-repeat: repeat-x;
	height: 2px;
	opacity: 0.1;

    &:last-child {
        display: none;
    }    
`

const mapDispatchToProps = dispatch => ({
    fetchSettings() {
        dispatch(fetchSettings())
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(SubSection);
