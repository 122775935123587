import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled, {css} from 'styled-components'
import { find } from 'lodash'
import Modal from 'react-modal';
import Fade from 'react-reveal/Fade';

import { Layout, Footer, MetaTags } from '../components'
import { container, padding, bgImage, bgIcon, hoverState } from '../styles/global'
import { lightRed, darkGrey, green, lightBlue, blue } from '../styles/colors'

import logo from '../assets/images/logo-watermark.png'
import iconSonic from '../assets/images/suite-ear-sonic-icon.png'
import iconSonicHover from '../assets/images/suite-ear-hover-active.png'
import iconVisual from '../assets/images/suite-eye-visual-icon.png'
import iconVisualHover from '../assets/images/suite-eye-hover-active.png'
import iconText from '../assets/images/suite-hand-text-icon.png'
import iconTextHover from '../assets/images/suite-hand-hover-active.png'
import GalleryModal from '../components/GalleryModal/GalleryModal';
import { isClient, media } from '../styles/utils';


class Gallery extends React.Component {

	state = {
        modalActive: false
	}
    
	componentWillMount = () => {            
        if (this.props.afterward) {
            this.getAfterwardData()
        } else if (this.props.theField) {
            this.getFieldData()
        } else {
            this.getData()
        }
    }

    getFieldData = () => {
        const { data } = this.props;

        // console.log(fieldData)
        const fieldData = JSON.parse(data.acf_json);
        
        this.setState({
            data: data,
            theField: true,
            items: JSON.parse(data.acf_json)[`${this.props.type}_items`],
            type: this.props.type,
            sectionAcf: {
                hero_colour: darkGrey
            },
            subSectionData: {
                title: 'The Field'
            },
            acf: JSON.parse(data.acf_json)
        })

        setTimeout(() => {
            isClient() && window.visitedFieldGallery(this.props.type)
        }, 1000)     
    }

    getAfterwardData = () => {
        const afterwardData = JSON.parse(this.props.afterwardData.acf_json);
        
        this.setState({
            afterward: true,
            items: this.props.afterwardItems,
            sectionAcf: {
                hero_colour: '#699eb9'
            },
            subSectionData: {
                title: 'Afterward'
            },
            acf: {
                text_content: afterwardData.gallery_text_content,
                background_image: afterwardData.gallery_background_image
            }
        })
    }

    getData = () => {
        const data = this.props.data.node;  
        const subSectionData = find(data.subs, { slug: this.props.slug });

        this.setState({      
            data: subSectionData,
            items: JSON.parse(subSectionData.acf_json)[`${this.props.type}_items`],
            acf: JSON.parse(subSectionData.acf_json),
            sectionData: data,
            sectionAcf: JSON.parse(data.acf_json),
            subSectionData: subSectionData,
            type: this.props.type,
            
            // Modal Testing

            // activeMedia: JSON.parse(subSectionData.acf_json)[`${this.props.type}_items`][0],
            // modalActive: true
        })
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (this.props.path !== prevProps.path) {
            this.getData()
        }
    }
    
    toggleModal = (data) => {
        this.setState({
            modalActive: !this.state.modalActive,
            activeMedia: data 
        })

        if (!this.state.modalActive == true) {
            window.scrollTo(0,0);
        }
    }

    changeModalMedia = (data) => {
        this.setState({
            activeMedia: data 
        })

        window.scrollTo(0,0)
    }
    
    renderGalleryLinks = () => {
        const { acf, sectionData, subSectionData, theField } = this.state;

        return ['sonic','visual','text'].map((type, i) => {  
            if (acf[`${type}_display_module`]) {   
                const link = theField ? `/app/the-field/gallery/${type}` : `/app/${sectionData.slug}/${subSectionData.slug}/gallery/${type}`;

                return (
                    <Icon
                        key={i}
                        type={type}
                        to={link}
                    />
                )   
            } 
        })
    }    

    renderGrid = () => {
        const { items, type, sectionAcf, afterward } = this.state;

        const gridItems = items && items.map((item, i) => {
            // console.log(item)

            if (afterward) {
                item = item.node;
                const acf = JSON.parse(item.acf_json);
                // console.log(acf)

                return (
                    <GridItem
                        color={sectionAcf.hero_colour}
                        onClick={() => this.toggleModal({
                            ...acf,
                            collection: true
                        })}
                        key={i}
                    >
                        <Heading>{item.title}</Heading>
                        <Image
                            image={acf.hero_image && acf.hero_image.sizes.medium2}
                        />
                    </GridItem>
                )
            }
            
            return (
                <GridItem
                    color={sectionAcf.hero_colour}
                    onClick={() => this.toggleModal(item)}
                    key={i}
                >
                    <Heading>{item.title_override !== "" ? item.title_override : item.title}</Heading>
                    <Image
                        image={item.image && item.image.sizes.medium2}
                    />
                </GridItem>
            )
        })

        return (
            <Grid>
                <GridTitle>
                    {afterward ? '' : type}
                </GridTitle>
                {gridItems}

                {this.renderExploreAfterward()}
            </Grid>
        )
    }

    renderExploreAfterward = () => {
        const { data, afterward } = this.state;

        if (!afterward && data.slug == 'release') { 
            return (
                <Afterward
                    to={'/app/afterward'}
                >
                    <Heading>
                        Explore <br/>the afterward
                    </Heading>
                    <BackArrow/>
                </Afterward>
            )
        }
    }

    renderSectionNav = () => {
        const { data, acf, sectionData, sectionAcf } = this.state;

        return (
            <SectionNav>
                {sectionData.subs.map((section) => {
                    const active = section.slug == data.slug;
                    return (
                        <>
                           <SectionNavItem
                                color={sectionAcf.hero_colour}
                                active={active}
                                onClick={() => navigate(`/app/${sectionData.slug}/${section.slug}`)}
                            >
                                <Heading>{section.title}</Heading>
                            </SectionNavItem>
                            <Line/>
                        </>
                    )
                })}
            </SectionNav>
        )
    }

	render() {       
        const { items, sectionData, modalActive, subSectionData, data, acf, sectionAcf, afterwardData, afterward } = this.state;

		return (    
			<Layout>
                <MetaTags 
                    title={`Adventures in the Field`}
                />
                <Fade
                    order={2}
                >
                    <Logo 
                        to={'/app/home'}
                        onClick={() => console.log('hello')}
                    />
                </Fade>
                
                <Wrapper
                    bgImage={acf.background_image.sizes.full_width}
                    modalActive={modalActive}
                >
                    <Container>

                        {sectionData && sectionAcf && (
                            <ParentSection>
                                <SectionTitle
                                    color={sectionAcf.hero_colour}
                                    to={`/app/${sectionData.slug}`}
                                >
                                    {sectionData.title}
                                </SectionTitle>
                                {this.renderSectionNav()}
                            </ParentSection>
                        )}

                        
                        <Title
                            color={sectionAcf.hero_colour}
                            afterward={afterward}
                        >
                            {subSectionData.title}
                        </Title> 

                        {!afterward && (
                            <Icons>
                                {this.renderGalleryLinks()}
                            </Icons>
                        )}
                                                        
                        {this.renderGrid()}
                        
                    </Container>
                </Wrapper>

                {/* Modal */}

                {this.state.modalActive && (
                    <GalleryModal
                        data={this.state.activeMedia}
                        colour={sectionAcf.hero_colour}
                        toggleModal={this.toggleModal}
                        changeModalMedia={this.changeModalMedia}
                    />
                )}

                <Footer 
                    color={sectionAcf.footer_text_colour}
                />

			</Layout>	
		)
	}
}



const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    min-height: 100vh;

    ${props => {
        if (props.modalActive) return css`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        `
    }}
`

const Container = styled.div`
	${container};
    ${padding};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.phone`
        padding-top: 80px;
    `}
`

const Heading = styled.div``

const Image = styled.div`
    background-image: url(${props => props.image});
    ${bgImage}
`

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 107px;
    height: 86px;
    z-index: 2;
    
    ${media.tablet`
        width: 88px;
        height: 71px;
        top: 5px;
        left: 5px;
    `}
`

const ParentSection = styled.div`
    margin-top: 31px;
    display: flex;
`

const SectionTitle = styled(Link)`
    display: flex;
    font-family: 'LatoWeb';
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 0.5em;
    color: ${props => props.color};

    ${media.phone`
        font-size: 16px;
    `}
`

const Title = styled.div`
    font-family: 'LatoWeb';
    font-size: 31px;
    line-height: 35px;
    letter-spacing: 15.5px;
    text-align: center;
    color: #4d4d4d;
    text-transform: uppercase;
    color: ${props => props.color};
    margin-top: 103px;

    ${media.phone`
        margin-top: 32px;
        font-size: 20px;
    `}

    ${props => {
        if (props.afterward) return css`
            margin-top: 150px;
            margin-bottom: 30px;
            color: white;

            ${media.phone`
                margin-top: 48px;
            `}
        `
    }}


`


const Schematic = styled.img`
    height: auto;
`

const Explore = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 88px;
`

const SubTitle = styled.div`
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.1em;
    color: ${props => props.color};
    text-align: center;
    margin-bottom: 24px;
`

// Icons

const Icons = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 46px;
`

const Icon = styled(Link)`
    display: flex;

    &:not(:last-child) {
        margin-right: 24px;
    }

    ${bgIcon}
    width: 52px;
    height: 52px;

    &:hover {
        transition: 0.4s all ease;
        cursor: pointer;
    }
    
    ${props => {
        if (props.type=='sonic') return css`
            background-image: url(${iconSonic});

            &:hover {
                background-image: url(${iconSonicHover});
            }
        `
        if (props.type=='visual') return css`
            background-image: url(${iconVisual});

            &:hover {
                background-image: url(${iconVisualHover});
            }
        `
        if (props.type=='text') return css`
            background-image: url(${iconText});

            &:hover {
                background-image: url(${iconTextHover});
            }
        `
    }}
`

// Grid

const Grid = styled.div`
    display: flex;
    flex-flow: row wrap;
    background: white;
    max-width: 957px;
    width: 100%;
    padding: 32px 22px 46px 54px;
    box-sizing: border-box;
    margin-top: 20px;
    margin-bottom: 180px;
    position: relative;

    ${media.phone`
        padding: 40px 5px 46px 15px;
        width: 100vw;
    `}
`


const GridTitle = styled.div`
    position: absolute;
    left: 14px;
    top: 0;
    width: 250px;
    text-align: right;
    transform: translateY(275px) rotate(-90deg);
    color: ${darkGrey};
    text-transform: uppercase;

    font-size: 22px;
    font-weight: 300;
    letter-spacing: 9px;
    transform-origin: 0 0;

    ${media.phone`
        width: auto;
        transform: translateX(-50%);
        top: 10px;
        left: 50%;
        font-size: 14px;
        letter-spacing: 6px;
    `}
`

const GridItem = styled.div`
    flex: 0 1 calc(33.3% - 32px);
    padding-bottom: calc(33.3% - 32px);
    background: ${props => props.color};
    margin-bottom: 32px;
    margin-right: 32px;
    display: flex;
    position: relative;
    cursor: pointer;

    ${media.tablet`
        flex: 0 1 calc(50% - 10px);
        padding-bottom: calc(50% - 10px);
        margin-right: 10px;
        margin-bottom: 10px;
    `}

    ${Heading} {
        font-family: 'LatoWeb';
        font-size: 18px;
        line-height: 33px;
        letter-spacing: 8.3px;
        text-align: center;
        transition: opacity 0.25s ease;
        text-transform: uppercase;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        z-index: 2;
        opacity: 0;
        width: 80%;
        word-break: break-word;

        ${media.phone`
            word-break: initial;
            font-size: 14px;
            line-height: 23px;
            letter-spacing: 4px;
        `}
    }

    ${Image} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity 0.25s ease;
        z-index: 1;
    }

    &:hover {
        ${Image} {
            opacity: 0.7;
        }

        ${Heading} {
            opacity: 1;
        }
    }
`


// Afterward

const BackArrow = styled.div`
    background-image: url(${require('../assets/images/orange-button.png')});
    ${bgImage};
    height: 36px;
    width: 36px;
    margin-right: 9px;  

    position: absolute;
    right: -48px;
    bottom: 5px;
`

const Afterward = styled(Link)`
    text-align: right;
    position: relative;
    display: flex;  
    flex-direction: column;

    position: absolute;
    bottom: -63px;
    right: 37px;

    ${Heading} {
        font-family: 'LatoWebBold';
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: #444444;
    }
`


// Section Nav

const SectionNav = styled.div`
    display: flex;
    align-items: center;
    margin-left: 31px;
    align-items: center;

    ${media.phone`
		display: none;
	`}
`

const SectionNavItem = styled.div`
    width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid ${props => props.color};
    cursor: pointer;
    transition: background 0.2s ease;
    position: relative;

    ${props => {
		if (props.active) return css`
			background: ${props => props.color};
		`
	}}

    &:hover {
        background: ${props => props.color};

        ${Heading} {
            opacity: 1;
        }
    }

    ${Heading} {
        font-size: 10px;
        letter-spacing: 0.2em;
        color: ${props => props.color};
        text-transform: uppercase;
        font-family: 'LatoWebBold';
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translate(-50%, 10%);
        transition: opacity 0.2s ease;
        opacity: 0;
        pointer-events: none;
    }
`

const Line = styled.div`
	width: 16px;
	background-image: linear-gradient(90deg, #000, #000 50%, transparent 75%, transparent 100%);    
	background-size: 4px 2px;
	background-position: 10px 0px;
    background-repeat: repeat-x;
	height: 2px;
	opacity: 0.1;

    &:last-child {
        display: none;
    }    
`


export default Gallery