import React, { useState, useEffect } from 'react';
import { useStaticQuery, Link, graphql, navigate } from 'gatsby'
import styled, { css, keyframes} from 'styled-components'
import { Fade } from '../transitions.js'
import { connect } from 'react-redux'
import posed from 'react-pose'
import { filter } from 'lodash'

import { updateSettings } from '../store/settings';

import { Layout, Footer, Modal, MetaTags } from '../components'
import GalleryModal from '../components/GalleryModal/GalleryModal';
import { container, padding, bgImage, bgIcon, hoverState } from '../styles/global'
import { orange, purple } from '../styles/colors'

import logo from '../assets/images/logo-watermark.png'
import { rgba } from 'polished';
import { isClient, media, width } from '../styles/utils';
import { forEach } from 'lodash';
import { useMount } from 'react-use';
import { isAuthenticated } from '../services/auth.js';

const getData = () => {
    const staticQuery = useStaticQuery(query);
    return {
        data: JSON.parse(staticQuery.wordpressPriming.acf_json),
    }
}

const Priming = ({updateSettings}) => {
    const { data } = getData()
    const [mounted, toggleMount] = useState(false)
    const [galleryModalActive, setGalleryModalActive] = useState(false)
    const [activeModalMedia, setActiveModalMedia] = useState(false)
    const modalManager = Modal.useModals()
    const [sectionsVisited, setSectionsVisited] = useState([])

    // Mount

    useMount(() => {
        if (data.audio_file) {
            updateSettings({
                audio: data.audio_file.url
            })
        }

        setTimeout(() => {
            generateSectionsVisited()
        }, 1000)
    })

    if (!mounted) {
        setTimeout(() => {
            toggleMount(true)
        }, 4000);
    }

    useEffect(() => {
        if (!isClient() || !sectionsVisited?.length) return
        window.localStorage.primingSectionsVisited = JSON.stringify(sectionsVisited)

        let visited = filter(sectionsVisited, (o) => {
            if (o.visited) return true
        })

        if (visited.length == sectionsVisited.length) {
            window.enableSection?.('the-field')
        }   
    }, [sectionsVisited])

    const generateSectionsVisited = () => {
        if (!isClient()) return

        const storedVisited = window.localStorage.primingSectionsVisited; 
        const storedVisitedTopLevel = window.localStorage.sections; 
        let allSectionVisited = false;

        if (storedVisitedTopLevel) { 
            const sections = JSON.parse(storedVisitedTopLevel)
            if (sections?.[2]?.inactive == false) allSectionVisited = true
        }

        let visitedData; 

        if (storedVisited) {
            visitedData = JSON.parse(storedVisited)
        } else {
            visitedData = sections(data);
            visitedData = visitedData.map((section, i) => {
                section.visited = false
                return section
            })
        }

        // Enable all sections if already visited 'The Field'

        if (allSectionVisited) {
            visitedData = sections(data);
            visitedData = visitedData.map((section, i) => {
                section.visited = true
                return section
            })
        }

        setSectionsVisited(visitedData)
    }

    const onSectionVisited = (sectionIndex) => {
        if (typeof sectionIndex == 'undefined') return;

        let newSections = Object.assign([], sectionsVisited);
        newSections[sectionIndex].visited = true
        setSectionsVisited(newSections)
    }
    
    const renderIntro = () => {
        return (
            <Fade order={2}>
                <Intro>
                    <Heading
                        dangerouslySetInnerHTML={{__html: 'THE<br />PRIMING'}}
                    />

                    <Text
                        dangerouslySetInnerHTML={{__html: data.tagline}}
                    />
                </Intro>
            </Fade>
        )
    }

    const toggleModal = (item, sectionIndex) => {
        onSectionVisited(sectionIndex)

        return modalManager.open(modal => (
            <Modal 
                {...item}
                {...modal}
                updateSettings={updateSettings}
                originalAudio={false}
                trackOpen
            />
        ))
    }

    const toggleGalleryModal = (data, sectionIndex) => {
        onSectionVisited(sectionIndex)
        setGalleryModalActive(!galleryModalActive)
        setActiveModalMedia(data)
    }

    const changeGalleryModalMedia = (data) => {
        setActiveModalMedia(data)
        window.scrollTo(0,0)
    }

    const renderSection = (item, i) => {
        return (
            <Section
                as={item.type == 'link' && Link}
                to={item.link}
                layout={sectionLayouts[i]}
                onClick={() => {
                    item.type == 'modal' && toggleModal(item, i)
                    item.type == 'galleryModal' && toggleGalleryModal(item.data, i)
                    
                    if (item.title == 'Maps' && item.data?.content_items?.length) {
                        changeGalleryModalMedia({
                            ...item.data?.content_items?.[0],
                            collectionItem: true,
                            content_items: item?.data?.content_items,

                        })
                    }
                }}
                inactive={!sectionsVisited?.[i]?.visited}
                disabled={i > 0 && !sectionsVisited?.[i - 1]?.visited}
            >
                <Info>
                    <Heading>
                        {i+1}<br/>
                        {item.title}
                    </Heading>
                </Info>
                <SectionBackground/>
            </Section>
        )
    }

    const renderSections = () => {
        const items = sections(data).map((item, i) => {
            return renderSection(item, i)
        })

        return (
            <Fade order={3}>
                <SectionWrapper>
                    {renderPurchaseButton()}

                    <Sections>
                        {items}
                    </Sections>
                </SectionWrapper>
            </Fade>
        )
    }

    const renderPurchaseButton = () => {
        if (isAuthenticated()) return

        return (
            <Purchase
                as={Link}
                to={'/register'}
            >
                Purchase
            </Purchase>
        )
    }
    
    return (
        <Layout
            textBox={data.text_content}
            textBoxAudio={data?.text_content_audio}
        >
            <Fade order={4}>
                <Logo 
                    to={'/app/home'}
                />           
            </Fade>

            <Wrapper
                bgImage={data.background_image.sizes.full_width}
                mobileBgImage={data.background_image_mobile?.sizes?.full_width}
            >
                <Container>
                    {renderIntro()}
                    {renderSections()}

                    {/* Gallery Modal */}

                    {galleryModalActive && (
                        <GalleryModal
                            data={activeModalMedia}
                            toggleModal={toggleGalleryModal}
                            changeModalMedia={changeGalleryModalMedia}
                        />
                    )}
                </Container>
            </Wrapper>
            
            <Footer
                color={'white'}
            />
        </Layout>	
    )
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Text = styled.div``
const Info = styled.div``


// Layout

const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${media.phone`
        min-height: 1000px;
    `}

    ${props => {
        if (props.mobileBgImage) return css`
            ${media.phone`
                background-image: url(${props.mobileBgImage});
            `}
        `
    }}
`

const Container = styled.div`
	${container}
	${padding}
    position: relative;
    align-items: flex-start;
`

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 107px;
    height: 86px;
    z-index: 2;
    
    ${media.tablet`
        width: 88px;
        height: 71px;
        top: 5px;
        left: 5px;
    `}
`

// Intro

const Intro = styled.div`
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: center;

    ${Heading} {
        font-size: 36px;
        line-height: 52px;
        letter-spacing: 0.5em;
        color: ${purple};
        font-weight: 200;
        width: 440px;
        text-align: center;
    }

    ${Text} {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.1em;
        font-family: 'LatoWebBoldItalic';
        color: #636363;
        margin-top: -10px;
        text-align: center;
    }

    ${media.phone`
        text-align: center;
        width: 90vw;
        top: 80px;

        display: flex;
        flex-direction: column;
        align-items: center;

        ${Heading} {
            font-size: 24px;
            line-height: 1.35em;
            width: auto;
        }

        ${Text} {
            font-size: 13px;
            line-height: 17px;
            letter-spacing: 0.05em;
        }
    `}
` 

// Sections

const Sections = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    ${media.phone`
        margin-top: 24px;
    `}
`

// Section


const SectionWrapper = styled.div`
    width: 1600px;
    height: 100vh;
    position: fixed;
    left: calc((100vw - 1600px) / 2);

    ${media.tablet`
        transform: scale(0.8);
    `}

    ${media.phone`
        position: relative;
        height: 100%;
        left: 0;
        width: 100vw;
        width: calc(100vw - 40px);
        transform: scale(1);
    `}
`

const fade = keyframes`
	0% {
		opacity: 100%;
	}

    50% {
        opacity: 40%;
    }

	100% {
		opacity: 100%;
	}
`;


const SectionBackground = styled.div``

const Section = styled.div`
    ${props => props.layout}
    width: 229px;
    height: 229px;
    display: flex;
    cursor: pointer;

    ${media.phone`
        width: 195px;
        height: 195px;
    `}

    ${SectionBackground} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0%;
        background-image: url(${require('../assets/images/ring.svg')});
        ${bgImage}
    }

    ${Info} {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        ${Subheading},
        ${Heading} {
            font-family: 'LatoWebBold';
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            margin: 0;
            color: #444444;
            max-width: 150px;

            ${media.tablet`
                font-size: 11px;
                max-width: 130px;
            `}
        }
    }

    ${props => {
        if (props.inactive) return css`
            ${SectionBackground} {
                animation: ${fade} 1.5s ease infinite;
            }
        `
    }}

    ${props => {
        if (props.disabled) return css`
            pointer-events: none;

            ${SectionBackground} {
                animation: none;
                opacity: 0.4;
            }
        `
    }}
`


// Section data

const sections = (data) => {
    let mapImages = []
    data.maps_images.forEach(image => {
        mapImages.push({
            collection: true,
            title: data.maps_title,
            text: data.maps_text,
            closeText: 'CLOSE SCREEN & RETURN TO PRIMING',
            type: 'visual',
            image_or_video: 'image',
            ...image
        })
    });

    return [
        {
            title: data.quest_title,
            type: 'modal',
            modal_layout: 'audio',
            modal_title: data.quest_title,
            audio: data.quest_audio
        },
        {
            title: data.maps_title,
            type: 'galleryModal',
            data: {
                collection: true,
                title: data.maps_title,
                text: data.maps_text,
                closeText: 'CLOSE SCREEN & RETURN TO PRIMING',
                hero_image: data.maps_images[0].image,
                content_items: mapImages
            }
        },
        {
            title: data.cellular_title,
            type: 'modal',
            modal_layout: 'audio',
            modal_title: data.cellular_title,
            audio: data.cellular_audio
        },
        {
            title: data.attunement_title,
            type: 'modal',
            modal_layout: 'audio',
            modal_title: data.attunement_title,
            audio: data.attunement_audio
        },
        {
            title: data.resources_title,
            type: 'galleryModal',
            data: {
                collection: true,
                title: data.resources_title,
                text: data.resources_text,
                closeText: 'CLOSE SCREEN & RETURN TO PRIMING',
                hero_image: data.resources_hero_image,
                content_items: data?.resources_content_items && data.resources_content_items.map(item => {
                    return {
                        ...item,
                        backToPriming: true,
                    }
                })
            }
        },
    ]
}

const sectionLayouts = [
    css`
        position: absolute;
        right: 21.25%;
        top: 31.8%;

        ${SectionBackground} {
            transform: rotate(-109.87deg);
        }
      
        ${media.phone`
            left: calc(50% - 98px + 72px);
            top: 227px;
        `}
    `,
    css`
        position: absolute;
        right: 32.5%;
        top: 39%;

        ${SectionBackground} {
            transform: rotate(-2.84deg);
        }

        ${media.phone`
            left: calc(50% - 98px - 72px);
            top: 300px;
        `}   
    `,
    css`
        position: absolute;
        right: 46.35%;
        top: 57.81%;

        ${SectionBackground} {
            transform: rotate(-115.99deg);
        }

        ${media.phone`
            left: calc(50% - 98px + 72px);
            top: 475px;
        `} 
    `,
    css`
        position: absolute;
        right: 57.75%;
        top: 65.82%;

        ${SectionBackground} {
            transform: rotate(-31.27deg);
        }

        ${media.phone`
            left: calc(50% - 98px + 45px);
            top: 630px;
        `}
    `,
       css`
        position: absolute;
        right: 63.31%;
        top: 49.12%;

        ${SectionBackground} {
            transform: rotate(153.94deg);
        }

        ${media.phone`
            left: calc(50% - 98px - 84px);  
            top: 542px;
        `}
    `,
]

// Purchase Button

const Purchase = styled.div`
    background-color: ${orange};
    min-height: 50px;
    padding: 0 30px;

    font-family: 'LatoWebBold';
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;

    position: absolute;
    right: 52.31%;
    top: 42.12%;

    cursor: pointer;
    ${hoverState};
    z-index: 2;
    
    ${media.phone`
        right: auto;
        left: calc(50% - 98px - 70px);  
        top: 250px;
        padding: 0 30px;
    `}
`

export const query = graphql`
	query {
		wordpressPriming {
			acf_json	
		}
	}
`

const mapDispatchToProps = dispatch => ({
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

export default connect(null, mapDispatchToProps)(Priming);


