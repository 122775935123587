import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, Link, graphql, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import posed from 'react-pose'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { updateSettings } from '../store/settings';

import { Layout, Footer, MetaTags } from '../components'
import { container, padding, bgImage, bgIcon, hoverState } from '../styles/global'
import { lightRed, orange } from '../styles/colors'

import logo from '../assets/images/logo.png'
import { rgba } from 'polished';
import { media, width } from '../styles/utils';

import toggleOrange from '../assets/images/accordion-toggle-white.png'
import playIcon from '../assets/images/play-button-white.svg'
import pauseIcon from '../assets/images/pause-button-white.svg'

const getData = () => {
    const staticQuery = useStaticQuery(query);
    return {
        data: JSON.parse(staticQuery.wordpressAfterward.acf_json)
    }
}

const Afterward = ({updateSettings, settings}) => {
    const [textActive, toggleText] = useState(false);
    const [textExpanded, toggleTextExpanded] = useState(false)
    const { data } = getData();
    const orangeBoxAudio = useRef(false)

    // Mount

    useEffect(() => {
        if (data.audio_file) {
            updateSettings({
                audio: data.audio_file.url
            })
        }

        // Toggle text box
        setTimeout(() => {              
            toggleText(true)
        }, 5000);
    }, []);

    const playAudio = (audio) => {
		updateSettings({
			audio: audio
		})
	}

    useEffect(() => {
        if (orangeBoxAudio?.current) {
            const audioFile = orangeBoxAudio?.current?.props?.src
            const isPlaying = orangeBoxAudio?.current?.isPlaying()

            if (isPlaying && settings.audio && settings.audio !== audioFile) {
                orangeBoxAudio?.current?.audio?.current?.pause()
            }
        }
    }, [orangeBoxAudio, settings])

    const renderExploreAfterward = () => {
        return (
            <AfterwardLink
                to={'/app/afterward/gallery'}
            >
                <Heading>
                    Explore <br/>the afterward
                </Heading>
                <BackArrow/>
            </AfterwardLink>
        )
    }

    const renderTextBox = () => {
        return (
			<TextBox
                active={textActive}
                textExpanded={textExpanded}
            > 
                <Close
                    onClick={() => {
                        toggleTextExpanded(false)
                        toggleText(!textActive)
                    }}
                />

                <Top>
                    <Title
                        onClick={() => !textActive && toggleText(!textActive)}
                    >
                        Afterward
                    </Title>
                </Top>

                {data.text_box_audio && (
                    <PlayAudio>
                        <Label>Play Audio</Label>
                            <AudioWrapper>
                                <AudioPlayer
                                    ref={orangeBoxAudio}
                                    src={data.text_box_audio}
                                    layout={'horizontal'}
                                    showJumpControls={false}
                                    timeFormat={'(mm:ss)'}
                                    muted={true}
                                    onPlay={e => playAudio(data.text_box_audio)}             
                                    onPause={e => {
                                        if (settings.audio == data.text_box_audio) {
                                            playAudio(false)
                                        }
                                    }}
                                />
                            </AudioWrapper>
                    </PlayAudio>
                )}

                <ReadMore
                    onClick={() => toggleTextExpanded(!textExpanded)}
                >
                    <Label>{`Welcome to the Afterward...`}</Label>
                    <Icon
                        image={toggleOrange}
                    />
                </ReadMore>

                <ExpandedContent
                    className={'text-expander'}
                    pose={textExpanded ? 'visible' : 'hidden'}
                >
                    <Description
                        dangerouslySetInnerHTML={{
                            __html: data.text_box,
                        }}
                    />
                </ExpandedContent>
                {renderExploreAfterward()}
            </TextBox>
        )
    }
    
    return (
        <Layout
            textBox={data.text_content}
            textBoxAudio={data?.text_content_audio}
        >
            <MetaTags 
                title={`Adventures in the Field`}
            />
            <Wrapper
                bgImage={data.background_image.sizes.full_width}
            >
                <Logo 
                    to={'/app/home'}
                />

                <Container>     
                    <Fade
                        onClick={() => !textActive && toggleText(!textActive)}
                    /> 

                    {renderTextBox()}
                </Container>
            </Wrapper>
            <Footer />
        </Layout>	
    )
}


const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    height: 100vh;
    overflow: hidden;
`

const Container = styled.div`
	${container}
	${padding}
    position: relative;
    align-items: flex-start;
`

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 165px;
    height: 134px;
    z-index: 10;
    
    ${media.tablet`
        width: 88px;
        height: 71px;
        top: 5px;
        left: 5px;
    `}
`

// Shared

const Title = styled.div``
const Heading = styled.div``
const Description = styled.div``

// Afterward

const BackArrow = styled.div`
    background-image: url(${require('../assets/images/orange-button.png')});
    ${bgImage};
    height: 36px;
    width: 36px;
    margin-right: 9px;  

    position: absolute;
    right: -48px;
    bottom: 5px;
`

const AfterwardLink = styled(Link)`
    text-align: right;
    position: relative;
    display: flex;  
    flex-direction: column;
    transform: translateX(-36px);
    margin-top: auto;
    display: none;

    ${Heading} {
        font-family: 'LatoWebBold';
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: white;
    }


    ${media.phone`
        padding-top: 24px;
    `}
`

// Text Box

// Fade Animation

const Fade = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
    padding-top: 200px;
    max-height: fit-content;
    box-shadow: 1px 1px 70px 80px #93c0c6;
    background: #93c0c6;

    display: none;

    ${media.phone`
        display: flex;
    `}
`

// Expandable text animation

export const ExpandedContent = posed.div({
    visible: { 
        opacity: 1,
        height: 'initial',
        flexBasis: '480px',
    },
    hidden: { 
        opacity: 0,
        height: 0,
        flexBasis: '0px',
    }
});

// Audio Player

const AudioWrapper = styled.div`
    flex-grow: 1;

    .rhap_container {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        outline: none;
    }

    .rhap_additional-controls,
    .rhap_volume-controls {
        display: none;
    }

    .rhap_progress-section {
        .rhap_current-time,
        .rhap_progress-container {
            display: none;
        }

        .rhap_total-time::before {
            content: '(';
        }

        .rhap_total-time::after {
            content: ')';
        }
    }

    .rhap_horizontal .rhap_controls-section {
        margin: 0;
        flex: none;
    }

    .rhap_main-controls-button {
        margin: 0;
        color: white;

        &, svg {
            width: 45px;
            height: 45px;
        }

        & {
            margin-right: -4px;
        }
    }
`

// Text Box

const Close = styled.div``
const Label = styled.div``
const PlayAudio = styled.div``
const ReadMore = styled.div``
const Top = styled.div``
const Icon = styled.div`
    background-image: url(${props => props.image});
    ${bgIcon};  
`

const TextBox = styled.div`
    margin: 175px 0 70px 95px;
    padding: 30px 30px;
    box-sizing: border-box;

    width: 386px;
    min-height: 311px;
    max-height: calc(100vh - 175px);
    position: relative;

    display: flex;
    flex-direction: column;
    background: transparent;
    transition: background 2s ease;

    ${media.phone`
        margin: 0 0 0 0;
        width: 100%;
        height: auto;
        padding-top: 120px;
        z-index: 12;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: none;
        pointer-events: none;   
        
        ${Title} {
            text-align: center;
            font-size: 24px;
            max-height: fit-content;
        }
    `}

    ${Close} {
        background-image: url(${require('../assets/images/close-icon-32px-white.png')});
        ${bgIcon};
        width: 18px;
        height: 18px;

        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 12;
        cursor: pointer;
        ${hoverState};
    }

    ${Top} {
        margin-bottom: 40px;
    }

    ${Title} {
        user-select: none;
        text-transform: uppercase;
        font-size: 26px;
        letter-spacing: 0.5em;
        line-height: 48px;
        color: white;
        opacity: 1;
        width: 320px;
        transition: color 0.25s ease;
    }

    .text-expander {
        flex: 0 1 100%;
        overflow-y: auto;
        margin-bottom: 16px;
    }

    ${Description} {
        margin-top: 14px;
        font-family: 'LatoWeb';
        font-size: 16px;
        line-height: 24px;
        padding-right: 20px;

        p {
            color: white;
        }

        a {
            color: #ffdecb;
            border-bottom: 1px solid #ffdecb;
            ${hoverState}
        }

        strong {
            font-family: 'LatoWebBold';
            font-size: 18px;
        }
    }

    ${ReadMore}, ${PlayAudio} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        user-select: none;

        ${Label}, .rhap_total-time {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.3em;
            color: white;
            text-transform: uppercase;

            span {
                text-transform: initial;
            }
        }
    }

    ${ReadMore} {
        border-top: 1px solid #ddd;
        padding: 20px 0;
        cursor: pointer;

        ${Icon} {
            width: 37px;
            height: 19px;
            transform: none;

            ${props => {
                if (props.textExpanded)
                    return css`
                        transform: rotate(-180deg);
                    `
            }}
        }
    }

    ${PlayAudio} {
        padding-bottom: 14px;

        ${Label} {
            margin-right: 6px;
        }
    }

    .text-expander,
    ${ReadMore},
    ${Close},
    ${PlayAudio} {
        display: none;
    }

    ${props => {
        if (props.active) return css`
            background: ${rgba('#414049', 0.65)};
            pointer-events: all !important;

            ${Title} {
                color: white;
            }

            .text-expander,
            ${Close},
            ${AfterwardLink}  {
                display: block;
            }

            ${ReadMore},
            ${PlayAudio} {
                display: flex;
            }
        `

        if (!props.active) return css`
            ${Title} {                
                cursor: pointer;
                transition: opacity 0.25s ease;

                &:hover {
                    opacity: 0.8;
                }
            }
        `
    }}
`
export const query = graphql`
	query {
		wordpressAfterward {
			title 
			slug
			acf_json
		}
	}
`

const mapDispatchToProps = dispatch => ({
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    settings: state.settings.settings
})


export default connect(mapStateToProps, mapDispatchToProps)(Afterward);


