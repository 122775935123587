import React from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { find, findIndex } from 'lodash'

import { media, tracking } from '../../styles/utils'
import { grey, darkGrey, orange } from '../../styles/colors'
import { container, bgIcon, padding, hoverState, bgImage } from '../../styles/global'

import { getSettings, fetchSettings, updateSettings } from '../../store/settings';

class GalleryModal extends React.Component {
    targetRef = React.createRef();

    constructor(props) {
		super(props);
		this.textWrapper = React.createRef();
	}

	state = {
        audioPlaying: false,
        imageModalOpen: false
    }
    
    renderMediaBlocks = () => {
        const { data } = this.props;

        const media = {
            'sonic': this.renderSonic(),
            'visual': this.renderVisual(),
            'text': this.renderText(),
        }
    
        return media[data.type]
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden'
	}

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data.type == 'text' && prevProps.data.slug !== this.props.data.slug) {
            this.textWrapper.current.scrollTop = 0;
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }
    
    renderText = () => {
        const { data, colour } = this.props;

        return (
            <Text
                layout={data.layout_type}
                colour={colour}
                contextVisible={data.display_context_area}
                ref={this.textWrapper}
            >
                {data.main_text_title !== "" && (
                    <Heading>{data.main_text_title}</Heading>
                )}

                {/* {data.main_text_author !== "" && (
                    <Author>{data.main_text_author}</Author>
                )} */}

                {data.main_text && (
                    <Description
                        dangerouslySetInnerHTML={{__html: data.main_text}}
                    />
                )}
            </Text>
        )
    }

    renderVisual = () => {
        const { data } = this.props;

        if (data.image_or_video=='video'){
            return (
                <AudioVideo
                    type="video"
                    dangerouslySetInnerHTML={{__html: data.video}}
                />
            )   
        }

        if(data.image_or_video=='image'){
            return (
                <>
                    {this.state.imageModalOpen && (
                        <ModalImage>
                            <Close
                                onClick={() => this.setState({imageModalOpen: false}) }
                            />
                            {/* <Img 
                                src={data.image && data.image.sizes.full_width}
                            /> */}
                            <Image 
                                image={data.image && data.image.sizes.full_width}
                            />
                        </ModalImage>
                    )}
                    <AudioVideo
                        type="image"
                        onClick={() => this.setState({imageModalOpen: true}) }
                    >       
                        <Image
                            image={data.image && data.image.sizes.medium2}
                        />
                        <Fullscreen/>
                        
                        {data.show_arrow && (
                            <DownArrow/>
                        )}
                       
                    </AudioVideo>
                </>
            )
        }
    }


    renderSonic = () => {
        const { data } = this.props;

        return (
            <AudioVideo
                type={data.type}
            >
                <Image
                    image={data.image && data.image.sizes.medium2}
                />
                <Ear/>
                <PlayPause
                    active={this.state.audioPlaying}
                    onClick={() => this.toggleAudio(data.audio.url)}
                />
            </AudioVideo>
        )
    }

    toggleAudio = (audioURL) => {
        const { audioPlaying } = this.state;
        const { updateSettings } = this.props;

        this.setState({
            audioPlaying: !audioPlaying
        })

        updateSettings({
            audio: audioPlaying ? null : audioURL
        })
    }

    renderAfterwardHero = () => {
        const { data } = this.props;
        if (!data.hero_image) return;

        return (
            <>
                {this.state.imageModalOpen && (
                    <ModalImage>
                        <Close
                            onClick={() => this.setState({imageModalOpen: false}) && window.scrollTo(0,0)}
                        />
                        <Image 
                            image={data.hero_image && data.hero_image.sizes.full_width}
                        />
                    </ModalImage>
                )}
                
                <AudioVideo
                    type="image"
                    onClick={() => this.setState({imageModalOpen: true}) }
                >       
                    <Image
                        image={data.hero_image && data.hero_image.sizes.medium2}
                    />
                    <Fullscreen />
                </AudioVideo>
            </>
            
        )
    }
    
    renderAfterwardContent = () => {
        const { data, colour } = this.props;
        
        return (
            <Context
                colour={colour}
                afterward={true}
            >
                <ContextWrapper>
                    <Info>
                        {data.title && <Title>{data.title}</Title>}
                        <Description
                            dangerouslySetInnerHTML={{__html: data.text}}
                        />
                    </Info>
                </ContextWrapper>
                <Back
                    onClick={() => this.props.toggleModal(null)}
                >
                    <BackArrow/>
                    {data.closeText && data.closeText}
                    {!data.closeText && `CLOSE SCREEN & RETURN TO AFTERWARD`}
                </Back>
            </Context>
        )
    }

    renderContext = () => {
        const { data, colour } = this.props;

        let heading = 'Try this...'
        
        if (data.meta_details_heading) {
            heading = data.meta_details_heading
        }

        return (
            <Context
                colour={colour}
            >
                <ContextWrapper>
                    <Info>
                        <Heading>Field Guide</Heading>
                        <Description
                            dangerouslySetInnerHTML={{__html: data.context_text}}
                        />
                    </Info>
                    <Meta>
                   
                        <Heading>{heading}</Heading>
                        
                        {data.meta_details_items && (
                            <MetaList>
                                {data.meta_details_items.map((meta, i) => {
                                    return (
                                        <MetaItem
                                            key={i}
                                        >
                                            <Key>{`${meta.title}:`}</Key>
                                            <Value>{meta.value}</Value>
                                        </MetaItem>
                                    )
                                })}
                            </MetaList>
                        )}

                        <Description
                            dangerouslySetInnerHTML={{__html: data.meta_details_text}}
                        />
                    </Meta>
                </ContextWrapper>
                <Back
                    onClick={() => this.props.toggleModal(null)}
                >
                    <BackArrow/>

                    {
                        this?.props?.data?.backToPriming
                            ? `CLOSE SCREEN & RETURN TO PRIMING `
                            : `CLOSE SCREEN & RETURN TO ${(data.collection || data.collectionItem) ? 'AFTERWARD' : `${data.type} PAGE`} `
                    }
                </Back>
            </Context>
        )
    }

    renderCollection = () => {
        const { data } = this.props; 

        const items = data.content_items && data.content_items.map((item, i) => {
            return (
                <CollectionItem
                    onClick={() => {
                        this.props.changeModalMedia({
                            ...item,
                            collectionItem: true,
                            content_items: data.content_items,
                        })
                        this.targetRef.current.scrollTo(0,0)
                    }}
                >
                    <Image
                        image={item.image && item.image.sizes.medium_large}
                    />
                </CollectionItem>
            )
            
        })

        return (
            <Collection>
                {items}
            </Collection>
        )
    }
	
	render() { 
        const { data, afterwardData, afterward } = this.props;

		return (
            <Wrapper
                ref={this.targetRef}
            >
                <Content>
                    {this.renderMediaBlocks()}
                    {data.collection && this.renderAfterwardHero()}
                    {(data.collection || data.collectionItem) && this.renderCollection()}
                    {data.collection && this.renderAfterwardContent()}
                    {!data.collection && data.display_context_area && this.renderContext()}
                </Content>

                {!this.state.imageModalOpen && (
                    <Close
                        onClick={() => this.props.toggleModal(null)}
                    />
                )}

                <Overlay/>
            </Wrapper>
		)
	}
}

// Shared

const Heading = styled.div``
const Description = styled.div``

const Image = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
`

const Img = styled.img``

const Close = styled.div`
    background-image: url(${require('../../assets/images/close-icon-32px-white.png')});
    ${bgImage};
    width: 25px;
    height: 25px;

    position: absolute;
    top: 47px;
    right: 46px;
    z-index: 12;
    cursor: pointer;
    ${hoverState};

    ${media.tablet`
        top: 24px;
        right: 24px;
    `}
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;    

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow-y: auto;
    z-index: 12;
`

const Content = styled.div`
    background: white;
    flex: 1;

    z-index: 12;
    background: white;
    max-width: 1034px;
    margin: 78px 83px 94px;

    display: flex;
    flex-direction: column;
    align-items: center;  

    ${media.tablet`
        margin: 78px 24px 94px;
    `}

    ${media.phone`
        margin: 80px 0;
    `}
`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, 0.8);
    z-index: 11;
`

const ModalImage = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, 0.8);
    z-index: 12;
    display: flex;

    ${Close} {
        z-index: 13;
    }

    ${Image} {
        align-self: center;
        margin: 0 auto;
        width: calc(100vw - 80px);
        height: calc(100vh);
        ${bgIcon}
    }

    ${Img} {
        align-self: center;
        margin: 0 auto;
        width: calc(100vw - 80px);
        height: auto;
    }
`
const Fullscreen = styled.div`
    background-image: url(${require('../../assets/images/icon-fullscreen.svg')});
    ${bgIcon}
    width: 20px;
    height: 20px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
`

// Media Blocks

// Text

const Author = styled.div``
const Title = styled.div``

const Text = styled.div`
    max-width: 589px;
    padding: 0 110px 60px;

    display: flex;
    flex-direction: column;
    align-items: center;  

    margin-top: 140px;

    ${media.phone`
        margin-top: 80px;
        padding: 0 32px 64px;
    `}

    ${Heading} {
        font-family: 'LatoWebLight';
        font-size: 30px;
        font-weight: 300;
        line-height: 44px;
        letter-spacing: 9px;
        color: ${darkGrey};
        text-transform: uppercase;    
    }

    ${Author} {
        margin-top: 15px;
        font-family: 'LatoWeb';
        font-size: 21px;
        line-height: 32px;
        text-align: center;
        color: #999999;
    }

    ${Description} {
        margin-top: 45px;
        font-family: 'LatoWeb';
        font-size: 16px;
        line-height: 24px;

        p {
            color: ${darkGrey};
        }

        a {
            color: ${orange};
            border-bottom: 1px solid ${orange};
            ${hoverState}
        }

        strong, b {
            font-family: 'LatoWebBold';
            font-size: 18px;
        }
    }

    max-height: 600px;
    overflow: scroll;

    ${media.phone`
        max-height: none;
    `}

    ${props => {
        if (props.contextVisible) return css`
            border-bottom: 1px solid #DDDDDD;
        `
    }}

    /* Layout 2 */

    ${props => {
        if (props.layout !== 'layout2') return css`
            ${Heading} {
                color: ${props.colour || darkGrey};   
            }

            ${Author} {
                display: none;
            }
        `

        if (props.layout == 'layout2') return css`
            margin-top: 97px;

            ${Description} {
                font-family: 'CrimsonRoman';
                font-size: 20px;
                line-height: 28px;
                margin-top: 45px;

                em {
                    font-family: 'CrimsonItalic';
                }

                strong {
                    font-family: 'CrimsonBold';
                    font-size: 22px;
                }
            }
        `
    }}
`

// Audio / Video

const AudioVideo = styled.div`
    height: 500px;
    width: 100%;
    background: black;
    position: relative;

    ${media.tablet`
        height: 320px;
    `}

    ${props => {
        if (props.type == 'sonic') return css`
            ${Image} {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
            }
        `
    }}

    ${props => {
        if (props.type == 'video') return css`
            height: 579px;
            display: flex;
            align-items: center;

            iframe {
                width: 100%;
                height: 100%;
            }
        `
    }}

    ${props => {
        if (props.type == 'image') return css`
            ${Image} {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                cursor: pointer;
            }
            ${Fullscreen}{
                position: absolute;
                bottom: 20px;
                right: 20px;
                z-index: 2;
                cursor: pointer;
            }
        `
    }}
`

const DownArrow = styled.div`
    position: absolute;
    background-image: url(${require('../../assets/images/image-arrow.png')});
    ${bgIcon}
    width: 48px;
    height: 26px;
    z-index: 2;
    bottom: 20px;
    right: -80px;
`

// Audio

const Ear = styled.div`
    background-image: url(${require('../../assets/images/ear-watermark.png')});
    ${bgIcon}
    width: 220px;
    height: 325px;

    position: absolute;
    top: calc(50% - 70px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    ${media.tablet`
        width: 110px;
        height: 162px;
    `}
`

const PlayPause = styled.div`
    background-image: url(${require('../../assets/images/play-button.svg')});
    ${bgIcon}
    width: 176px;
    height: 176px;
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(calc(-50% + 15px), 0);
    z-index: 2;
    cursor: pointer;
    ${hoverState}

    ${media.tablet`
        width: 96px;
        height: 96px;
    `}

    ${props => {
        if (props.active) return css`
            background-image: url(${require('../../assets/images/pause-button.svg')});
        `
    }}
`

// Context

const Info = styled.div``
const Meta = styled.div``
const ContextWrapper = styled.div``

const Context = styled.div`
    padding: 68px 110px 71px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    ${media.tablet`
        padding: 68px 48px 71px;
    `}

    ${media.phone`
        padding: 32px 32px;
    `}


    ${ContextWrapper} {
        display: flex;


        ${media.phone`
            flex-direction: column;
        `}
    }

    ${Title} {
        margin-bottom: 2em;
        font-family: 'LatoWeb';
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        color: ${darkGrey};
        text-transform: uppercase;
    }

    ${Heading} {
        font-family: 'LatoWeb';
        font-size: 24px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: ${props => props.colour};
        margin-bottom: 32px;
    }

    ${Description} {
        font-family: 'LatoWeb';
        font-size: 16px;
        line-height: 24px;

        p {
            color: ${darkGrey};
        }

        a {
            color: ${orange};
            border-bottom: 1px solid ${orange};
            ${hoverState}
        }

        strong {
            font-family: 'LatoWebBold';
            font-size: 18px;
        }
    }

    ${Info} {
        flex: 1 0 60%;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        ${media.phone`
            padding-right: 0;
        `}
    }

    ${Meta} {
        padding-left: 25px;
        flex: 1 0 40%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #DDDDDD;
        box-sizing: border-box;


        ${media.phone`
            padding-left: 0;
            border-left: none;
            margin-top: 32px;
        `}
    }

    ${props => {
        if (props.afterward) return css`
            max-width: 80%;

            ${media.tablet`
                max-width: none;
            `}
                
            h3 {
                font-family: 'LatoWeb';
                font-size: 24px;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                color: ${props => props.colour};
                margin-bottom: 32px;
            }
        `
    }}
`

const MetaList = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'LatoWeb';
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
`

const MetaItem = styled.div`
    display: flex;
`

const Key = styled.div`
    color: ${darkGrey};
    font-family: 'LatoWebBold';
    margin-right: 4px;
`

const Value = styled.div`
    color: ${darkGrey};
`

const Back = styled.div`
    margin-top: 70px;
    font-family: 'LatoWeb';
    font-size: 16px;
    line-height: 24px;
    color: ${orange};
    text-transform: uppercase;
    display: flex;
    align-items: center;    
    ${hoverState};

    ${media.phone`
        flex-direction: column;
        align-items: flex-start;
        max-width: 200px;
    `}
`

const BackArrow = styled.div`
    background-image: url(${require('../../assets/images/orange-button.png')});
    ${bgImage};
    height: 36px;
    width: 36px;
    transform: rotate(180deg);
    margin-right: 9px;  

    ${media.phone`
        margin-bottom: 14px;  
    `}
`

// Afterward Items

const Collection = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
    padding: 0 48px;
    box-sizing: border-box;

    ${media.tablet`
        flex-flow: row wrap;  
    `}
`

const CollectionItem = styled.div`
    flex: 0 1 calc(calc(100% / 4) - 15px);
    margin-bottom: 15px;
    cursor: pointer;

    ${media.tablet`
        flex: 0 1 calc(calc(100% / 8) - 15px);
        margin-bottom: 15px;
    `}

    ${media.phone`
        flex: 0 1 calc(calc(100% / 4) - 15px);
    `}

    &:not(:last-child) {
        margin-right: 15px;
    }

    ${Image} {
        padding-bottom: 100%;
    }
`
const mapDispatchToProps = dispatch => ({
    fetchSettings() {
        dispatch(fetchSettings())
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(GalleryModal);
