import React from 'react'
import { Link, navigate, graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { Fade } from '../transitions';

import { Layout, Footer, MetaTags } from '../components'
import { container, padding, bgImage, bgIcon, hoverState } from '../styles/global'
import { lightRed, darkGrey, green, lightBlue } from '../styles/colors'

import triadRings from '../assets/images/triad-rings-other-pages.png'
import logo from '../assets/images/logo-watermark.png'

import { getSettings, fetchSettings, updateSettings } from '../store/settings';
import { isClient, media } from '../styles/utils';
import { getBackground } from '../utils';

class Section extends React.Component {

	state = {
        changeBackground: false
    }

	componentWillMount = () => {
        this.getData()
    }    
    
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                changeBackground: true
            })
        }, 4000);
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (this.props.path !== prevProps.path) {
            this.getData()
        }
    }

    getData = () => {
        const { updateSettings } = this.props;

        const data = this.props.data.node;
        const acf = JSON.parse(data.acf_json);
        

        this.setState({
            data: data,
            acf: acf
        })

        if (acf.audio_file) {
            updateSettings({
                audio: acf.audio_file.url
            })
        }          
    }
    
    navigateTo = (link) => {
        navigate(link)
    }

    // componentWillUnmount = () => {
    //     const { updateSettings } = this.props;
      
    //     updateSettings({
    //         audio: null
    //     })
    // }

    renderTriad = () => {
        const { data } = this.state;

        if (data.subs) {
            return (
                <Fade order={3}>
                    <Triad>
                        <Rings />
    
                        {data.subs.map((sub, i) => {     
                            let sub_acf = JSON.parse(sub.acf_json);
                            return (
                                <SubLink
                                    key={i}
                                    index={i}
                                    to={`/app/${data.slug}/${sub.slug}`}
                                    color={sub_acf.hero_colour}
                                >
                                    <Text>
                                        {sub.title}
                                    </Text>
                                </SubLink>
                            )
                        })}
                    </Triad>
                </Fade>
            )
        }
    }

	render() {       
        const { data, acf, changeBackground } = this.state;
                
		return (
			<Layout
                textBox={acf.text_content}
                textBoxAudio={acf?.text_content_audio}
                delay={acf.background_image_initial}
            >
                <MetaTags 
                    title={`Adventures in the Field`}
                />
                
                <Wrapper
                    bgImage={getBackground(acf, changeBackground)}
                >
                    <Fade
                        order={4}
                    >
                        <Logo 
                            to={'/app/home'}
                        />
                    </Fade>
                    
                    <Container>
                        <Fade order={2}>
                            <Title
                                color={acf.hero_colour}
                                section={data.slug}
                            >
                                {data.title}
                            </Title>
                        </Fade>

                        {this.renderTriad()}
                        
                    </Container>
                </Wrapper>

                <Footer 
                    color={acf.footer_text_colour}
                />
			</Layout>	
		)
	}
}


const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    height: 100vh;
    transition: background 2s ease;
`

const Container = styled.div`
	${container};
    ${padding};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Heading = styled.div``
const Description = styled.div``

const Title = styled.div`
    font-family: 'LatoWebLight';
    text-transform: uppercase;
    font-size: 36px;
    letter-spacing: 0.3em;
    color: #4d4d4d;
    text-align: center;
    margin-bottom: 40px;
    margin-left: 48px;
    max-width: 350px;
    margin-top: -70px;
    color: ${props => props.color};
 
    ${media.phone`
        font-size: 28px;
        line-height: 36px;
        margin-left: 0;
    `}
`

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 107px;
    height: 86px;
    
    ${media.tablet`
        width: 88px;
        height: 71px;
        top: 5px;
        left: 5px;
    `}
`


const Triad = styled.div`
    position: relative;
    width: 438px;
    height: 408px;

    ${media.tablet`
        width:  330px;
        height: 300px;
    `}
`


const Rings = styled.div`
    position: absolute;
    top: 0;
    left:0;
     ${bgIcon}
    background-image: url(${triadRings});
    width: 100%;
    height: 100%;
`

const Text = styled.div`
    color: ${props => props.color};
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.3em;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);     
    ${hoverState};
`

const SubLink = styled(Link)`
    position: absolute;
    margin: 0 40px;
    width: 160px;
    height: 240px;

    ${Text} {
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.3em;
        text-align: center;
        color: ${darkGrey};
        position: relative;
        top: 50%;
        transform: translateY(-50%);     
        font-family: 'LatoWebBold';
        ${hoverState};
        color: ${darkGrey}
    }

    /* Substantiate - top right, Mobilise – bottom right, Adapt – mid left */

    ${props => {
        if (props.index==0) return css`
            top: 4px;
            left: 169px;
            ${media.tablet`
                left: 100px;
            `}
        `
        if (props.index==1) return css`
            top: 168px;
            left: 193px;
            ${media.tablet`
                top: 125px;
                left: 120px;
            `}
        `
        if (props.index==2) return css`
            top: 87px;
            left: 0px;
            ${media.tablet`
                top: 65px;
                left: -25px;
            `}  
		`
    }}


    /* ${props => {
        if (props.index==0) return css`
            top: 87px;
            left: 0px;
            ${media.tablet`
                top: 65px;
                left: -25px;
            `}
        `
        if (props.index==1) return css`
            top: 4px;
            left: 169px;

            ${media.tablet`
                left: 100px;
            `}
        `
        if (props.index==2) return css`
            top: 168px;
            left: 193px;

            ${media.tablet`
                top: 125px;
                left: 120px;
            `}
		`
    }} */

    ${media.tablet`
        width: 160px;
        height: 170px;

        ${Text} {
            font-size: 11px;
        }
    `}

    
`


const mapDispatchToProps = dispatch => ({
    fetchSettings() {
        dispatch(fetchSettings())
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(Section);
